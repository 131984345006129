import LoadingBar from "react-top-loading-bar"
import { useEffect, Suspense, useRef, useState } from "react"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { globalState, queryClient } from "config"
import { useRequest } from "hooks"
import { get } from "lodash"

import { Spinner } from "components"
import { Emitter, api } from "services"
import storage from "services/Storage"
import useClearCache from "hooks/useClearCache"

import AdminRoutes from "./Admin"
import GuestRoutes from "./Guest"
import Pages from "./Pages"

function Routes() {
  useClearCache()
  const loadingBar = useRef()
  const [isFetching, setIsFetching] = useState(false)
  const state = useQuery({
    queryKey: ["globalState"],
    queryFn: () => globalState,
    initialData: () => globalState,
    staleTime: Infinity,
  })

  const params = new URL(window.location).searchParams

  let profile
  try {
    profile = JSON.parse(sessionStorage.getItem("user"))
  } catch (e) {
    //
  }

  const getMe = useRequest({
    url: profile ? `users/getMe?id=${get(profile, "id")}` : "users/getMe",
    route: "",
    enabled: false,
    retry: 0,
    onSuccess: (data) => {
      const user = {
        ...(get(data, "data") || {}),
        stats: get(data, "stats"),
      }
      try {
        sessionStorage.setItem("user", JSON.stringify(user))
      } catch (e) {
        //
      }
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        user,
        isAuth: true,
      }))
      setIsFetching(false)
      storage.set("user", user)
    },
    onError: () => {
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        isAuth: false,
      }))
      setIsFetching(false)
    },
  })

  useRequest({
    queryKey: ["warehouse"],
    url: `warehouses/${get(state.data, "warehouse_id")}`,
    retry: 0,
    onSuccess: (data) => {
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        warehouses: get(data, "data"),
      }))
    },
    enabled: !!get(state.data, "warehouse_id"),
  })

  useEffect(() => {
    if (!storage.get("user") && state.data.isAuth) {
      setIsFetching(true)
      getMe.refetch()
    }
  }, [state.data.isAuth])

  const checkVersion = () => {
    fetch("/app-version.json").then(async (res) => {
      const { version } = (await res.json()) || {}
      const currentVersion = storage.get("app-version")

      if (version !== currentVersion) {
        storage.set("app-version", version)
        window.location.reload(true)
      }
    })
  }

  useEffect(() => {
    const token = params.get("token")
    if (token) {
      sessionStorage.setItem("token", token)
    }
    api.interceptors.request.use((request) => {
      if (loadingBar.current) loadingBar.current.continuousStart()
      return request
    })

    api.interceptors.response.use((res) => {
      if (loadingBar.current) loadingBar.current.complete()
      return res
    })

    Emitter.on("REFETCH_PROFILE", () => {
      getMe.refetch()
    })

    window.onfocus = () => {
      checkVersion()
    }
  }, [])

  return (
    <div className="app">
      {isFetching ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <RouterProvider
            router={createBrowserRouter([
              ...(state.data.isAuth && state.data.warehouse_id ? AdminRoutes : []),
              ...(!state.data.isAuth ? GuestRoutes : []),
              ...(state.data.isAuth && !state.data.warehouse_id
                ? [
                    {
                      path: "/",
                      element: <Pages.PickWarehouse />,
                    },
                    {
                      path: "*",
                      element: <Navigate to="/" />,
                    },
                  ]
                : []),
            ])}
          />
        </Suspense>
      )}
      <LoadingBar color="#6a6cf7" ref={loadingBar} shadow />
    </div>
  )
}

export default Routes
